.navb {
    padding-top: 30px;
    background: #e7e7e7;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    position: relative;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
    z-index: 999;
    width: 100%;
    padding: 0 17px;
    max-width: 2000px;
  }
  
  .nav-logo {
    padding-left: 40px;
  }
  
  .navb a.text {
    margin-left: 15px;
    color: #000000;
  }
  
  .navb a.text p {
    letter-spacing: normal;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
  
  .nav-logo img {
    width: 70px;
  }
  
  .menu-icon {
    display: none;
  }
  
  .nav-menu {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    text-align: center;
  }
  
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #000000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
  }
  
  .nav-links::before {
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-out;
  }
  
  @keyframes navLinkAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .nav-links:hover,
  .dropdown-menu li a:hover {
    color: #000000;
    animation-name: navLinkAnimation;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    left: 0;
    z-index: 1;
    padding: 0;
    margin: 0;
    min-width: 200px;
    list-style: none;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .dropdown-menu li {
    height: auto;
    display: block;
    padding: 8px 12px;
  }
  
  .dropdown-menu li:hover {
    background-color: #000000;
  }
  
  .dropdown-menu li a {
    color: #000000;
    text-decoration: none;
    display: block;
    padding: 0;
  }
  
  .dropdown-menu li a:hover {
    color: #ffffff;
  }
  
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 50px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      overflow-y: scroll;
    }
    .navbar-container {
      height: auto;
      overflow-x: hidden;
    }
    .nav-menu.active {
      background-color: #ffffff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-item {
      height: 80px;
      width: 100%;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  }

  .btn-container{
    margin-right: 70px;
  }

  .btn-translation {
    color: black;
    background-color: white;
    border: 1px solid black;
    margin-bottom: 10px;
  }

  .btn-translation:hover {
    color: white;
    background-color: black;
  }
  
  