
.testimonial1-container {
  text-align: center; 
}

.testimonial-title {
  margin-top: 100px;
}
.testimonial1-container h3{
  margin-top: 20px;
  font-size: 50px;
}

.testimonial1-image {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 50px;
}

.testimonial1-content{
  background-color: rgb(78, 78, 78);
  align-items: center;
  justify-content: center;
  text-align: center;
}

.testimonial1-context h4 {
  background-color: rgb(78, 78, 78);
  color: white;
  padding: 30px;
  border-radius: 8px;
  margin-top: 50px;
  margin-left: 15%; 
  margin-right: 15%;
  line-height: 2;
}

.testimonial1-context p {
  
  color: white;
  padding: 30px;
  border-radius: 8px;
  margin-top: 50px;
  margin-left: 15%; 
  margin-right: 15%;
  line-height: 2;
  font-size: 22px;
  text-align: left;
}



@media screen and (max-width: 968px) {
  .testimonial1-image{
    width: 100%;
  }
}