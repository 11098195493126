.history-container {
  text-align: center; 
}
.history-image {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}
    
.history-content{
  align-items: center;
  justify-content: center;
  text-align: center;
}

.history-context h3 {
  color: black;
  margin-top: 120px;
  font-size: 28px;
}

@media screen and (max-width: 968px) {
  .history-image{
    width: 100%;
  }
}