.big-box {
  border: 4px solid #aaaaaa;
  padding: 20px;
  margin: 40px auto; 
  width: 80%;
}
.weekly-title {
  color: rgb(172, 2, 2);
  margin: 20px;
  display: flex;
  align-items: center;
}
.weekly-title h2{
  margin-left: 20px;
  font-size: 24px;
}

.list-container {
  display: flex;
  flex-wrap: wrap;
 
  background-color: #e7e7e7;
}
.list-container p{
 margin: 20px;
}

.list-item {
  width: calc(100% - 5px);
  border: 2px solid #939393;
  margin: 10px;
  padding: 10px;
  display: flex;
  background-color: white;
  align-items: center;
}

.left {
  display: flex; 
  align-items: center;
  flex-direction: row;
}

.left img {
  width: 80px; 
  height: 50px; 
  margin-right: 10px;
}

.left p {
  margin-left: 25px; 
}

.right-button {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.right-button img{
  margin-right: 8px;
   cursor: pointer;
}

.pagi {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.pagi button {
  margin-right: 10px;
  border: 1px solid #000;
  padding: 5px 10px;
  background-color: #fff;
  color: #323232;
}

.pagi button.active {
  background-color: #323232;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  

  .list-item {
    flex-direction: column; /* Display items in a column on smaller screens */
    align-items: flex-start; /* Align items to the start of the column */
  }

  .left {
   
    display: flex; 
    
    flex-direction: column;
  }
  
  
  
  
  .right-button {
    margin-left: auto;
    display: flex;
    
    
  }
}