.text-sphere-container {
    position: relative;
    width: 40%; 
    aspect-ratio: 1/1; 
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, red, orange, yellow, green, cyan, blue, purple);
    border-radius: 60%; 
    margin: 50px auto;
    overflow: hidden;
}
.tagcloud {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    letter-spacing: 0.0625em;
    font-size: 1.1em;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateZ(0); 
}
.tagcloud--item {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
}

.tagcloud--item:hover {
    color: rgb(0, 0, 0);
}