.parent-container {
  text-align: center; 
}

.parent-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  gap: 20px;
}

.parent-context h3 {
  color: black;
  margin-top: 120px;
  font-size: 36px;
  font-weight: 525;
}

@media screen and (max-width: 968px) {
  .parent-image {
    flex-wrap: wrap;
  }

  .parent-image img {
    width: calc(50% - 10px);
    margin: 5px;
  }
}

@media screen and (max-width: 480px) {
  .parent-image img {
    width: 100%;
  }
}
