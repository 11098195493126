/* App.css */
.info {
    background: #ffffff;
    padding: 20px;
    text-align: center; /* Center-align the content */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensure the section takes the full viewport height */
  }
#contact{
  margin: 100px auto;
} 
  .title {
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .card {
    background: #fff;
    padding: 10px 15px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .card p {
    font-size: 16px;
  }
  
  .icon {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .part {
    color: #fff;
    padding: 20px;
    text-align: center;
  }

  
  .social {
    font-size: 24px;
    margin: 10px;
  }
  
  .social a {
    color: #333;
    text-decoration: none;
    margin: 10px;
    transition: color 0.3s;
  }
  
  .social a:hover {
    color: #0077b5;
  }
  /* App.css */
.form {
    background: #f0f0f0;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  label {
    font-size: 16px;
    color: #333;
    margin: 5px 0;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    background: #0077b5;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  button:hover {
    background: #005ea0;
  }
  
  .container.contact_container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
  }
  .contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  .contact_option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
  }
  .contact_option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
  }
  .contact_option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  
  }
  .contact_option a {
    margin-top:0.7rem ;
    display: inline-block;
    font-size: 0.8rem;
  }
  /*=======Form===========*/
  form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  input, textarea{
    width: 100%;
    padding:1.5rem ;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
  
  }
  /*---------------medium device---------*/
  @media screen and (max-width:1024px){
    .container.contact_container{
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }
  
  /*---------------small device---------*/
  @media screen and (max-width:600px){
    .container.contact_container{
      width: var(--container-width-sm);  
    }
  }