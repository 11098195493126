@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif ;
}
.sermons-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.sermons-page h1 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 2rem;
}

.video-card {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video-card iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-card h2 {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.video-card p {
  font-size: 1rem;
}

.pagi {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.page-item {
  margin: 0 0.5rem;
}

.page-link {
  display: block;
  padding: 0.5rem;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.page-link:hover {
  background-color: #f5f5f5;
}

.btn-translation {
  color: black;
  background-color: white;
  border: 1px solid black;
  margin-bottom: 10px;
}

.btn-translation:hover {
  color: white;
  background-color: black;
}






.list-container {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 2rem;
  
}

.list-container p {
  
  /* text-align:left; */
  padding-top: 1px;
}



.list-item {
  display: flex;
  align-items: center;
  /* max-width: 1200px; */
}

.list-item img {
  margin-right: 20px; /* Add margin-right to create space between the image and title */
}
.title1 {
  flex: 2; /* Adjust the width as needed */
  
  padding: 5px;
  border: 0px solid #ccc;
}

.desc {
  flex: 2; /* Adjust the width as needed */
  padding: 5px;
  border: 0px solid #ccc;
}

.date {
  flex: 1; /* Adjust the width as needed */
  padding: 5px;
  border: 0px solid #ccc;
}




.right {
  margin-left: auto; /* Push the button to the right */
}

.right button {
  margin-left: 10px;
}



@media only screen and (max-width: 768px) {
  .sermons-page {
    padding: 1rem;
  }

  .sermons-page h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .video-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
  }

  .video-card h2 {
    font-size: 1.25rem;
  }

  .video-card p {
    font-size: 0.875rem;
  }

  .page-link {
    padding: 0.25rem;
    font-size: 0.875rem;
  }

  .list-item {
    flex-direction: column; /* Display items in a column on smaller screens */
    align-items: flex-start; /* Align items to the start of the column */
  }

  .list-item img {
    margin-right: 0; /* Remove margin-right for smaller screens */
    margin-bottom: 5px; /* Add margin-bottom to create space between items */
  }

  .right button {
    margin-left: 0; /* Remove left margin for buttons */
    margin-top: 10px; /* Add margin-top to separate buttons */
  }
}
