.alpha-container {
  text-align: center; 
}
.alpha-image {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
  margin-bottom: 50px;
}
    
.alpha-content{
  align-items: center;
  justify-content: center;
  text-align: center;
}

.alpha-context p {
  background-color: rgb(78, 78, 78);
  color: white;
  padding: 30px;
  border-radius: 8px;
  margin-top: 50px;
  margin-left: 15%; 
  margin-right: 15%;
  line-height: 2;
}

@media screen and (max-width: 968px) {
  .alpha-image{
    width: 100%;
  }
}