* {
    padding: 0px;
    margin: 0px;
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    outline: 0;
  }
  
  ::before,
  ::after {
    box-sizing: border-box;
  }
  
  body::-webkit-scrollbar {
   width: 1em;
  }
  
  body::-webkit-scrollbar-track {
   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  body::-webkit-scrollbar-thumb {
   background-color: darkgrey;
   outline: 1px solid slategrey;
  }
  body {
   /* background-color: #5f6dbd;*/
    overflow-x: hidden;
  }
  .btn {
    user-select: none;
  }
  ul {
    list-style: none;
  }
  a {
    text-decoration: none;
  }