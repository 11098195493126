.hero2-image {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.welcome-image {
  margin-bottom: 35px;
}

.welcome {
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
}

.welcome h3 {
  font-size: 32px;
  color: rgb(149, 1, 1);
  margin-bottom: 30px;
}

.hrLines{
  width: 50%;
  border-top: 2px solid black; 
  font-size: 100px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 40px;
}

.upcoming {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
}

.upcoming h4 {
  font-size: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.card {
  justify-content: center;
  text-align: center;
}

.card img {
  border-radius: 5px;
}
      
 .card h4 {
  font-size: 20px;
  justify-content: center;
  font-weight: 600;
  padding: 15px;
 }

 .show-more-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;

}
 /* In your Home.css file or any other CSS file you are using */

.verse-container {
  position: relative;
  text-align: center;
  margin: 0px;
}

.bible {
  width: 33.33%; /* 设置宽度为百分比 */
}

.verse-image {
  width: 100%;
}


.verse-text {
  color:white;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  width: 80%;
   /*height: 300px; Fixed height */
  overflow: hidden; /* Hide scrollbar */
  max-height: 100%;/* Adjust the maximum height as needed */
  overflow: hidden; /* Hide scrollbar overflow-y: auto;*/
  
  background-color: rgba(255, 255, 255, 0.01); /* Adjust background color and opacity as needed */
  padding: 10px;
  border-radius: 8px;
  animation: scrollText 30s linear infinite; /* Adjust animation duration as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@keyframes scrollText {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100% );
  }
}

.arrow-btn {
  background-color: grey; /* Set the background color */
  color: #ffffff; /* Set the text color */
  font-size: 18px; /* Adjust the font size */
  padding: 10px 15px; /* Adjust the padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px; /* Add margin for spacing */
}

.left-arrow {
  float: left;
  
}

.right-arrow {
  float: right;
}
.show-more-btn {
  display: none;
}

@media screen and (max-width: 968px) {
  .show-more-btn {
    cursor: pointer;
    margin-top: 10px; /* Adjust the top margin as needed */
    background-color: #888; /* Grey color, you can change this */
    color: #fff; /* White text, you can change this */
    padding: 8px 12px;
    border: none;
    border-radius: 3px;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  
  .show-more-btn:hover {
    background-color: #666;/* Darker blue on hover, you can change this */
  }
  .show-more-btn {
    display: block; /* Show the "Show More" button on smaller screens */
  }
  .arrow-btn {
    display: none;
  }
}

@media screen and (max-width: 968px) {
  .hero2-image .bible {
    display: none; /* 隐藏具有 .bible 类名的图片 */
  }
}