.pastor-title{
    text-align: center;
    font-weight: 500;
    margin: 80px auto;
    padding-bottom: 10px;
    font-size: 36px;
  }
.cards{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  .image-card {
    width: calc(25% - 30px); 
    padding: 10px;
    background-color: #fff;
  }
  
  .image-card img {
    width: 100%;
    height: auto;
    border-radius: 15px;
  }
  
  .image-card h2 {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  @media (max-width: 768px) {
    .image-card {
      width: calc(100% - 40px);
    }
  }