
.testimonial1-container {
  text-align: center; 
}

.testimonial-title {
  margin-top: 100px;
}

.testimonial1-image {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 50px;
}

.testimonials-context h4 {
  background-color: rgb(78, 78, 78);
  color: white;
  padding: 30px;
  border-radius: 8px;
  margin-top: 50px;
  margin-left: 15%; 
  margin-right: 15%;
  line-height: 2;
}

.testimonials-context {
  background-color: rgb(233, 233, 233);
  border-radius: 10px;
  margin-left: 15%; 
  margin-right: 15%;
}

.testimonials-context p {
  color: rgb(33, 33, 33);
  padding: 20px;
  padding-left: 50px;
  padding-right: 50px;
  line-height: 1.5;
  font-size: 22px;
  text-align: left;
}

.btn-back {
  margin-top: 50px;
  color:black;
  background-color:rgb(233, 233, 233);
  border: 1px solid black;
  margin-bottom: 10px;
  font-size: 24px;
}

.btn-back:hover {
  color: rgb(78, 78, 78);
  background-color: white;
  border: 1px solid black;
}
@media screen and (max-width: 968px) {
  .testimonial1-image{
    width: 100%;
  }
}