.kid-container {
  text-align: center; 
}

.kid-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  gap: 50px;
}

.kid-title {
  margin-top: 120px;
}

@media screen and (max-width: 968px) {
  .kid-image {
    flex-wrap: wrap;
  }

  .kid-image img {
    width: calc(50% - 10px);
    margin: 5px;
  }
}

@media screen and (max-width: 480px) {
  .kid-image img {
    width: 100%;
  }
}
