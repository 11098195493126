
.giving-container {
  text-align: center; 
}

.give-image {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
  margin-bottom: 50px;
  border: 8px solid grey;
}

.giving-content{
  align-items: center;
  justify-content: center;
  text-align: center;
}

.giving-context p {
  background-color: rgb(78, 78, 78);
  color: white;
  padding: 30px;
  border-radius: 8px;
  margin-top: 150px;
  margin-left: 15%; 
  margin-right: 15%;
  line-height: 2;
  font-size: 22px;
}

.giving-text {
  position: absolute;
  bottom: 260px; 
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5); 
  color: white;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 20px;
  opacity: 0; 
  white-space: pre-line; 
  display: block; 
  line-height: 2; 
  text-align: left;
  transition: opacity 1s ease;
}

.giving-text:hover {
  opacity: 1; 
}


@media screen and (max-width: 968px) {
  .give-image{
    width: 100%;
  }
}