.video-container{
  margin: 100px auto;
  padding: 20px;
  font-size: 50px;
  justify-content: center;
  text-align: center;
  background-color: rgb(224, 224, 224);
  color: rgb(44, 44, 44);
  width: 50%;
  border-radius: 5px;
}
