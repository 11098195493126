.image-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 10px;
  }
  
  .image-card img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .card-heading {
    font-size: 18px;
    color: #333;
    text-align: center;
  }
  